import {
  mdiAirplaneTakeoff, mdiClipboardTextMultipleOutline, mdiClipboardListOutline, mdiCar, mdiBed,
} from '@mdi/js'

export default [
  // PRODUCTOS
  // flights
  {
    title: 'menu.flights',
    icon: mdiAirplaneTakeoff,
    pertenece: ['flight_list'],
    children: [
      {
        title: 'lbl.list',
        icon: mdiClipboardTextMultipleOutline,
        to: 'flights-list',
        permiso: 'flight:list',
        pertenece: 'flight_list',
      },
      {
        title: 'lbl.disponibilidad',
        icon: mdiClipboardTextMultipleOutline,
        to: 'flights-availability',
        permiso: 'flight:list',
        pertenece: 'flight_availability',
      },

      /* {
        title: 'menu.hotels',
        icon: mdiBed,
        permiso: 'hotel:list',
        pertenece: 'hotel',
      }, */
    ],
  },

  // cars
  {
    title: 'menu.cars',
    icon: mdiCar,
    pertenece: ['car'],
    children: [
      {
        title: 'lbl.list',
        icon: mdiClipboardTextMultipleOutline,
        to: 'cars-list',
        permiso: 'car:list',
        pertenece: 'car_list',
      },
      {
        title: 'lbl.disponibilidad',
        icon: mdiClipboardTextMultipleOutline,
        to: 'cars-availability',
        permiso: 'car:list',
        pertenece: 'car_availability',
      },
    ],
  },

  // HOTELS
  {
    title: 'menu.hotels',
    icon: mdiBed,
    pertenece: ['hotel'],
    children: [
      {
        title: 'lbl.list',
        icon: mdiClipboardTextMultipleOutline,
        to: 'hotels-list',
        permiso: 'hotel:list',
        pertenece: 'hotel_list',
      },

      /* {
        title: 'lbl.disponibilidad',
        icon: mdiClipboardTextMultipleOutline,
        to: 'cars-availability',
        permiso: 'car:list',
        pertenece: 'car_availability',
      }, */
    ],
  },

  // NOMENCLADORES
  {
    title: 'menu.nomenclador',
    icon: mdiClipboardListOutline,
    pertenece: ['nom_airport', 'nom_aerolinea'],
    children: [
      {
        title: 'menu.flights',
        icon: mdiAirplaneTakeoff,
        permiso: 'flight:list',
        children: [
          {
            title: 'lbl.airports',
            icon: mdiClipboardTextMultipleOutline,
            to: 'airport-list',
            permiso: 'flight:list',
            pertenece: 'nom_airport',
          },
          {
            title: 'lbl.searchAirport',
            icon: mdiClipboardTextMultipleOutline,
            to: 'airport-search',
            permiso: 'flight:list',
            pertenece: 'nom_airport_search',
          },
          {
            title: 'menu.aerolinea',
            icon: mdiClipboardTextMultipleOutline,
            to: 'aeroline-list',
            permiso: 'flight:list',
            pertenece: 'nom_aerolinea',
          },
          {
            title: 'lbl.clases',
            icon: mdiClipboardTextMultipleOutline,
            to: 'class-list',
            permiso: 'flight:list',
            pertenece: 'nom_class',
          },
        ],
      },

      {
        title: 'menu.cars',
        icon: mdiCar,
        permiso: 'car:list',
        children: [
          {
            title: 'menu.oficinaRenta',
            to: 'offices-rent-cars-list',
            permiso: 'car:list',
            pertenece: 'nom_oficina_renta_cars',
          },
          {
            title: 'menu.ptoRecogida',
            to: 'pto-recogida-cars-list',
            permiso: 'car:list',
            pertenece: 'nom_tags_pto_recogida_cars',
          },
          {
            title: 'menu.trasnmision',
            to: 'transmition-cars-list',
            permiso: 'car:list',
            pertenece: 'nom_transmision_cars',
          },
          {
            title: 'menu.rentadora',
            to: 'rents-cars-list',
            permiso: 'car:list',
            pertenece: 'nom_rentadora_cars',
          },
        ],
      },

      {
        title: 'menu.hotels',
        icon: mdiBed,
        permiso: 'hotel:list',
        children: [
          {
            title: 'menu.category',
            to: 'categorias-hotels-list',
            permiso: 'hotel:list',
            pertenece: 'nom_categorias_hotels',
          },
          {
            title: 'menu.typeDestination',
            to: 'type-destination-hotels-list',
            permiso: 'hotel:list',
            pertenece: 'nom_tipo_destino_hotels',
          },
          {
            title: 'lbl.destinos',
            to: 'host-destination-hotels-list',
            permiso: 'hotel:list',
            pertenece: 'nom_host_destination_hotels',
          },
          {
            title: 'menu.intereses',
            to: 'interests-hotels-list',
            permiso: 'hotel:list',
            pertenece: 'nom_intereses_hotels',
          },
          {
            title: 'menu.servicios',
            to: 'services-hotels-list',
            permiso: 'hotel:list',
            pertenece: 'nom_servicios_hotels',
          },
        ],
      },

      /*
      {
        title: 'menu.hotels',
        icon: mdiBed,
        permiso: 'hotel:list',
        pertenece: 'hotel',
      }, */
    ],
  },
]
