export default {
  plataforma: {
    title: 'Datos generales de la plataforma',
  },
  login: {
    title: 'Panel de administración',
    subTitle: 'Inicia sesión en tu cuenta',
    forgot: '¿Has olvidado tu contraseña?',
    remember: 'Recuérdame',
    newAccount: '¿Nuevo en nuestra plataforma?',
    createAccount: 'Crea una cuenta',
  },
  register: {
    title: 'La aventura comienza aquí',
    subtitle: '¡Haga que la administración de su aplicación sea fácil!',
    exist: '¿Ya tienes una cuenta?',
    btnIn: 'Inicia sesión',
    btnUp: 'Regístrame',
    titlePerson: 'Información General',
    subtitlePerson: 'Información del Contacto',
    namePerson: 'Nombre y Apellidos',
    emailPerson: 'Correo Electrónico',
    codePais: 'Código del País',
    noTelefo: 'Número de teléfono',
    subtitleCompany: 'Información de la Empresa',
    nameCompany: 'Nombre de la Empresa',
    siteWebCompany: 'Sitio Web',
    titleFact: 'Datos de Facturación',
    nameFact: 'Nombre Fiscal',
    cifNif: 'NIF/NIT',
    address: 'Dirección',
    codePostal: 'Código Postal',
    locality: 'Localidad',
    country: 'País',
    profile: 'Elige tus Perfiles',
    profileSub: 'Cuéntanos un poco sobre su Empresa',
    msgExistCompany: 'Lo sentimos ya usted esta registrado. Por favor contacte a su administrador.',
  },
  forgot: {
    title: '¿Has olvidado tu contraseña?',
    subtitle: 'Ingrese su correo electrónico y le enviaremos instrucciones para restablecer su contraseña',
    btn: 'Enviar enlace de reinicio',
    btnBack: 'Atrás para iniciar sesión',
    errorUserInex: 'Lo sentimos pero no hemos encontrado a {email} en nuestro sistema.',
    errorUserInac: 'Lo sentimos pero {email} no está activo en nuestro sistema.',
    sendEmail: 'Se ha enviado notificación a {email}. Siga los pasos para recuperar su cuenta',
  },
  reset: {
    title: 'Restablecer la contraseña',
    subtitle: 'su nueva contraseña debe ser diferente de las contraseñas utilizadas anteriormente',
    btn: 'Establecer nueva contraseña',
    btnBack: 'Atrás para iniciar sesión',
    msgSuccess: 'Contraseña reestablecida. Por favor inicie sesión',
  },
  activeAcount: {
    title: 'Active su Cuenta',
    subTitle: 'con estas credenciales podrá administrar su Empresa',
    password: 'Nueva Contraseña',
    confirmPassword: 'Confirme Contraseña',
    btn: 'Activar',
    msgInvalidToken: 'Su token es inválido',
    msgSuccess: 'Usuario activado. Por favor inicie sesión',
  },
  lbl: {
    list: 'Listado',
    disponibilidad: 'Disponibilidad',
    page: 'Página',
    perPage: 'Items por página',
    method: 'Tipo de petición',
    params: 'Parámetros',
    request: 'Solicitud',
    response: 'Respuesta',
    email: 'Correo electrónico',
    password: 'Contraseña',
    new_password: 'Nueva Contraseña',
    confirmation_password: 'Confirmar Contraseña',
    user: 'Usuario',
    name: 'Nombre',
    apellidos: 'Apellidos',
    changeFoto: 'Cambiar foto',
    changePassword: 'Cambiar contraseña',
    identificador: 'Identificador',
    color: 'Color',
    code: 'Código',
    slug: 'Slug',
    active: 'Activo',
    showFront: 'Registro',
    description: 'Descripción',
    itemsPerPage: 'Por página',
    actions: 'Acciones',
    rol: 'Rol',
    permisoEdit: 'Actualizar permiso',
    perfil: 'Perfil',
    typeProduct: 'Tipo de Producto',
    products: 'Productos',
    pcFee: 'Porcentaje o Fee',
    pc: 'Porcentaje',
    fee: 'Valor',
    infoAdd: 'Información Adicional',
    info: 'Información',
    ventaDirectas: 'Ventas directas',
    valor: 'Valor',
    pc1: '%',
    all: 'Todas',
    company: 'Empresa',
    contacto: 'Contacto',
    profiles: 'Perfiles',
    dateSoliciti: 'Fecha Solicitud',
    manageSolic: 'Gestionar Aprobación',
    acceptSolic: 'Aprobar Solicitud',
    denegSolic: 'Denegar Solicitud',
    address: 'Dirección',
    telefonos: 'Teléfonos',
    telefono: 'Teléfono',
    horarios: 'Horarios',
    horario: 'Horas',
    from: 'Desde',
    apertura: 'Apertura',
    cierre: 'Cierre',
    to: 'Hasta',
    ida: 'Ida',
    ida_vuelta: 'Ida y vuelta',
    nultiple_destino: 'Múltiples destinos',
    back: 'Vuelta',
    addFligth: 'Agregar vuelo',
    longitud: 'Longitud',
    latitud: 'Latitud',
    showMap: 'Mostrar Mapa',
    hideMap: 'Ocultar Mapa',
    airport: 'Aeropuerto',
    airports: 'Aeropuertos',
    searchAirport: 'Buscar Aeropuertos',
    city: 'Ciudad',
    state: 'Estado',
    states: 'Estados',
    zip: 'Zip',
    country: 'País',
    provinceEstado: 'Provincia / Estado',
    locality: 'Localidad',
    typeAfiliado: 'Tipo Afiliado',
    createAdmin: 'Crear Administrador',
    dataAdmin: 'Datos del Administrador',
    promover: 'Promover',
    dateVuelo: 'Fecha del vuelo',
    dateArrival: 'Fecha y hora de llegada',
    vuelo: 'Vuelo',
    de: 'de',
    a: 'a',
    day: 'días',
    mas: 'más de',
    vuelos: 'vuelos',
    cars: 'autos',
    contratos: 'contratos',
    selectVuelo: 'Seleccionar vuelo',
    ruta: 'Ruta',
    numeroVuelo: 'Número de vuelo',
    operador: 'Operado por',
    aeronave: 'Aeronave',
    origen: 'Origen',
    terminal: 'Terminal',
    puerta: 'Puerta',
    destino: 'Destino',
    destinos: 'Destinos',
    searchDestino: 'Buscar Destinos',
    text: 'Texto',
    textSearch: 'Cadena a buscar',
    iata: 'Código IATA',
    checkIn: 'Despacho',
    departure: 'Salida',
    hour_departure: 'Hora de salida',
    arrival: 'Arribo',
    hour_arrival: 'Hora de llegada',
    durationVuelo: 'Duración del vuelo',
    durationEscala: 'Duración Escala',
    durationEscalaRango: 'hasta {time} horas',
    durationViaje: 'Duración del viaje',
    escala: 'escala',
    escalad: 'escalas',
    escalas: 'Escalas',
    escala1: 'Todos los vuelos',
    escala2: 'Vuelo directo',
    escala3: 'Hasta 1 escala',
    duration: 'Duración',
    origenSearch: '¿Cuál es el origen?',
    noResultSearchFlight: 'No encontramos resultados para su búsqueda',
    hacia: 'Hacia',
    destinoSearch: '¿Hacia donde?',
    citySearch: 'Seleccione la ciudad',
    charter: 'Charter',
    flight: 'Vuelo',
    selectFlight: 'Seleccione el vuelo',
    clases: 'Clases',
    clase: 'Clase',
    cupo: 'Cupos',
    price_adult: 'Precio Adulto',
    price_min: 'Precio más económico',
    adl: 'Adl',
    adulto: 'Adulto',
    adultos: 'Adultos',
    childs: 'Niños',
    childsFlight: 'de 2 a 12',
    infants: 'Infantes',
    infantsFlight: 'menos de 2',
    mnr: 'Mnr',
    menor: 'Menor',
    infat: 'Infante',
    price_child: 'Precio Niños',
    price_infant: 'Precio Infante',
    infant_free: 'Infante Gratis',
    equipaje_cabina: {
      title: 'Equipaje en cabina',
      dimensiones: 'Dimensiones',
      peso: 'Peso',
      precio: 'Precio',
      para: 'Aplica para',
      adul: 'Adulto',
      child: 'Menor',
      inf: 'Infante',
    },
    equipaje_bodega: {
      title: 'Equipaje en bodega',
      dimensiones: 'Dimensiones',
      addMaleta: 'Adicionar Maleta',
      peso: 'Peso',
      precioMaleta: 'Maleta',
      max_exceso_equipaje: 'Peso máximo',
      exceso_equipaje: 'Exceso de Equipaje',
      tasa_despacho: 'Tasa despacho',
      para: 'Aplica para',
      adul: 'Adulto',
      child: 'Menor',
      inf: 'Infante',
    },
    maleta_adicional: {
      title: 'Maleta adicional',
      dimensiones: 'Dimensiones',
      peso: 'Peso',
      cant: 'Cant.',
      para: 'Aplica para',
      adul: 'Adul.',
      child: 'Men.',
      inf: 'Inf.',
    },
    precio_adicional: {
      title: 'Precio adicional',
      um: 'Unidad medida',
      de: 'de',
      a: 'a',
      precio: 'Precio',
      para: 'Aplica para',
      adul: 'Adul.',
      child: 'Men.',
      inf: 'Inf.',
    },
    comidas_bordo: {
      title: 'Comidas a bordo',
      para: 'Aplica para',
      adul: 'Adul.',
      child: 'Men.',
      inf: 'Inf.',
    },
    reembolso: {
      title: 'Reembolsos',
      para: 'Aplica para',
      adul: 'Adul.',
      child: 'Men.',
      inf: 'Inf.',
    },
    cambio_fecha: {
      title: 'Cambios de fecha',
      para: 'Aplica para',
      adul: 'Adul.',
      child: 'Men.',
      inf: 'Inf.',
    },
    elec_asiento: {
      title: 'Elección de asientos',
      para: 'Aplica para',
      adul: 'Adul.',
      child: 'Men.',
      inf: 'Inf.',
    },
    frecuency: 'Frecuencia',
    frecuencias: 'Frecuencias',
    frecuenciasFlight: 'Frecuencias del vuelo',
    temporadas: 'Temporadas',
    validez: 'Validez',
    vigencia: 'Vigencia',
    fromValidez: 'Validez desde',
    toValidez: 'Validez hasta',
    dimension: 'Dimensión',
    largo: 'Largo',
    alto: 'Alto',
    ancho: 'Ancho',
    tipo: 'Tipo',
    lineal: 'Lineal',
    um: 'UM',
    peso: 'Peso',
    pesoMax: 'Max.',
    fechasVuelo: 'Fechas de vuelo',
    todosDay: 'Todos',
    semanaDay: 'Días de la semana',
    especifDay: 'Días específicos',
    detailContrate: 'Condiciones del Contrato',
    tarifa: 'Tarifas',
    conditionContrate: 'Política Tarifaria',
    rulesTarifa: 'Reglas de la tarifa',
    otherTarifa: 'Otras tarifas con equipaje',
    date: 'Fecha',
    dateCreate: 'Fecha de creación',
    car: 'Auto',
    cantPuertas: 'Cantidad de puertas',
    cantPaxs: 'Cantidad de paxs',
    cantMaletas: 'Maletas',
    small: 'Pequeñas',
    big: 'Grandes',
    umComb: 'UM',
    capacityComb: 'Capacidad de combustible',
    consumoComb: 'Consumo de combustible',
    motor: 'Motor',
    publico: 'Publicado',
    sugerido: 'Sugerido',
    searchImages: 'Busque las imágenes',
    galery: 'Galería',
    details: 'Detalles',
    edadConductor: 'Edad del Conductor',
    proveedor: 'Proveedor',
    aerolinea: 'Aerolínea',
    validFrom: 'Válido desde',
    validTo: 'Válido hasta',
    change: 'Cambios',
    dataOld: 'Datos antiguos',
    propuesta: 'Propuesta de Cambios',
    oficina: 'Oficina',
    time_completo: 'Abierto 24 horas',
    bag: 'Equipaje',
    bagNoInclude: 'Equipaje no incluido en el precio',
    pieza: '{n} pieza | {n} piezas',
    bagHand: 'Equipaje de mano',
    filtros: 'Filtros',
    byMejorOpcion: 'Mejor opción',
    byMasEconomico: 'Más económicos',
    byMasRapido: 'Más rápido',
    action: 'Acción',
    recogida: 'Recogida',
    dateRecogida: 'Fecha Recogida',
    entrega: 'Entrega',
    dateEntrega: 'Fecha Entrega',
    recogidaEntrega: 'Recogida y Entrega',
    entregaDiffRecogida: 'Entregar en diferente lugar',
    hotelDestinos: 'Ciudad, región o nombre de alojamiento',
    dateIn: 'Fecha de entrada',
    fromAl: 'desde las',
    dateOut: 'Fecha de salida',
  },
  btn: {
    send: 'Enviar',
    apply: 'Aplicar',
    continue: 'Continuar',
    search: 'Buscar',
    close: 'Cerrar',
    cancele: 'Cancelar',
    insert: 'Insertar',
    generate: 'Generar',
    create: 'Crear',
    copy: 'Copiar',
    update: 'Actualizar',
    updatePermisos: 'Actualizar Permisos',
    edit: 'Editar',
    delete: 'Eliminar',
    clone: 'Clonar',
    programation: 'Programación',
    see: 'Ver',
    back: 'Regresar',
    import: 'Importar',
    activar: 'Activar',
    desactivar: 'Desactivar',
    all: 'Todos',
    acceptChange: 'Aceptar Cambios',
    canceleChange: 'Rechazar Cambios',
    uploadLote: 'Subir por lotes',
  },
  msg: {
    error: {
      406: 'No se pudo completar la solicitud. Existen datos inválidos.',
    },
    dataLong: 'El texto a buscar debe contener al menos 3 caracteres.',
    dataRequiere: 'Debe introducir sus datos.',
    dataSpecific: 'Debe especificar un rango de fechas.',
    infoSuccess: 'Información actualizada.',
    fileUpload: 'Archivo en proceso.',
    invalidCredentials: 'Credenciales inválidas.',
    userInactive: 'Usuario inactivo',
    datasRequiere: 'Debe llenar los datos requeridos.',
    noAutorice: 'No está autorizado a realizar esta operación.',
    notMatch: 'No coinciden las contraseñas.',
    deleteItem: '¿Está seguro que desea eliminar {item}?',
    deleteMarkups: '¿Está seguro que desea eliminar este Markups?',
    deleteFrecuencia: '¿Está seguro que desea eliminar la frecuencia {item}?',
    deleteFlight: '¿Está seguro que desea eliminar el vuelo {item}?',
    errorDeleteFlight: 'No es posible eliminar el vuelo {item}. Existen contratos relacionados a este.',
    selectFlight: 'Upsss!!!... debe seleccionar un vuelo para continuar.',
    noNumberFlight: 'Upsss!!!... no se encuentra el vuelo {numero}.',
    dupliqueFrecuencia: 'Revice sus datos, está duplicando tarifas al contrato.',
    userExist: 'Ya existe un usuario con el email {email}',
    airlineExist: 'Ya existe una aerolínea con el identificador {identificador}',
    airplaneExist: 'Ya existe una aeronave con el nombre {identificador}',
    airportExist: 'Ya existe el aeropuerto {identificador}',
    claseExist: 'Ya existe una clase con el nombre {identificador}',
    tarifaVueloExist: 'Ya existe una tarifa con el nombre {identificador}',
    dimensionExist: 'Ya existe una dimensión del tipo {identificador}',
    pesoExist: 'Ya existe un peso del tipo {identificador}',
    transmisionExist: 'Ya existe la transmisión {identificador}',
    typeAutoExist: 'Ya existe el tipo de auto {identificador}',
    typeAutoNote: 'Los tipos o clasificaciones del auto corresponden a la carrocería automotriz.',
    marcaExist: 'Ya existe la marca {identificador}',
    modeloExist: 'Ya existe el modelo {identificador}',
    combustibleExist: 'Ya existe el combustible {identificador}',
    caracteristicaExist: 'Ya existe la característica {identificador}',
    categoryExist: 'Ya existe la categoría {identificador}',
    roleExist: 'Ya existe el rol {identificador}',
    rentadoraExist: 'Ya existe la rentadora {identificador}',
    tarifaExist: 'Ya existe la tarifa {identificador}',
    tarifaMaxExist: 'Si dejamos en blanco el máximo de días se tomará como ilimitado, ej: +{identificador}',
    edadMaxExist: 'Si dejamos en blanco el máximo de edad se tomará como ilimitado, ej: +{identificador}',
    sendEmail: 'Se ha enviado notificación a {email}',
    existFlight:
      'Upsss!!!... ya existe un vuelo registrado con el número {numero}. Por favor, verifique que haya introducido bien los datos. Si fuera necesario, vaya al listado de vuelos disponibles para editarlo.',
    existCars:
      'Upsss!!!... ya existe un auto registrado con el nombre {name}. Por favor, verifique que haya introducido bien los datos. Si fuera necesario, vaya al listado de vuelos disponibles para editarlo.',

    confirmChange: 'Para que sus cambios tengan efecto necesita volver a realizar inicar sesión. ¿Desea continuar?',
    emptySeason: 'Upsss!!!... debe definir al menos una temporada.',
    activeUser:
      '¿Está seguro que desea activar el usuario {email}? Esta acción notificará al usuario para que cree su perfil.',
    desactiveUser:
      '¿Está seguro que desea desactivar el usuario {email}? Esta acción no permitirá que entre a la plataforma hasta tanto no se vuelva a activar.',

    noElementShow: 'No se encontraron elementos.',
    acceptChange: '¿Está seguro que desea aceptar los nuevos cambios?',
    rejectChange: '¿Está seguro que desea rechazar los nuevos cambios?',

    verifyTarifa: 'Consulta las reglas de la tarifa para saber si se admiten cambios.',
    verifyReembolso: 'Consulta las reglas de la tarifa para conocer la política de reembolso.',
    noResultFlightSearch: 'Upsss!!!... no se encontró resultados para su criterio de búsqueda.',

    copyText: 'Se ha copiado {text}',
    noAsociedCompany: 'No se encontró una empresa asociada a este usuario.',
    successToken: 'Tokens generados con éxito.',
    generateToken:
      '¿ Está seguro que desea resetear sus token? Esta acción romperá cualquier vínculo de aplicaciones externas que hagan uso de sus token actuales.',
    apiText1:
      'Las claves de API identifican el proyecto (la APP o el sitio web) que realiza la llamada a la propia API. Los tokens de autenticación identifican al usuario (la persona) que usa la APP o el sitio web.',
    apiText2:
      'Es muy importante que sepas que quien tenga tu API Key tendrá acceso a tu cuenta, por lo que tienes que ser extremadamente cuidadoso con ella.',

    noResultFlightCotizacionTitle: 'Cambiar parámetros de búsqueda',
    noResultFlightCotizacionBody: 'Prueba a buscar vuelos en otras fechas o de diferentes aeropuertos',
  },
  menu: {
    dashboard: 'Tablero',
    subheader: 'HERRAMIENTAS',
    acceso: 'Acceso',
    booking: 'Booking',
    comitions: 'Comisiones',
    cotizador: 'Cotizador',
    reservas: 'Reservas',
    contability: 'Contabilidad',
    nomenclador: 'Nomencladores',
    typeAfiliado: 'Tipo de afiliado',
    permisos: 'Permisos',
    roles: 'Roles',
    perfilUser: 'Perfiles',
    users: 'Usuarios',
    usersSon: 'Usuarios Hijos',
    usersPropio: 'Propios',
    usersAdmin: 'Administrador',
    userRegister: 'Afiliados',
    companies: 'Compañías',
    agentes: 'Agentes',
    operations: 'Operaciones',
    logs: 'Logs',
    markups: 'Markups',
    administration: 'Administración',
    configuration: 'Configuración',
    dataGeneral: 'Identificador',
    jsonAirport: 'JSON Aeropuertos',
    products: 'Productos',
    flights: 'Vuelos',
    cars: 'Autos',
    hotels: 'Hoteles',
    contratos: 'Contratos',
    class: 'Clases',
    tarifas: 'Tarifas',
    airport: 'Aeropuertos',
    aeronave: 'Aeronaves',
    aerolinea: 'Aerolíneas',
    dimensiones: 'Dimensiones equipaje',
    pesos: 'Pesos equipaje',
    cardFidelidad: 'Tarjetas de Fidelidad',
    codeOFAC: 'Códigos OFAC',
    trasnmision: 'Transmisión',
    typeCar: 'Tipo de Carrocería',
    marca: 'Marca',
    modelo: 'Modelo',
    combustible: 'Combustible',
    caracteristicas: 'Características',
    category: 'Categorías',
    rentadora: 'Rentadora',
    oficinaRenta: 'Oficinas de renta',
    ptoRecogida: 'Punto de recogida',
    typeDestination: 'Tipo de destinos',
    intereses: 'Intereses',
    servicios: 'Servicios y Facilidades',
  },
  rules: {
    min6: 'Al menos 6 carácteres',
    upload1MB: '¡El tamaño del avatar debe ser inferior a 1 MB!',
  },
  footer: {
    allRights: 'Todos los derechos reservados',
    powered: 'Powered by',
  },
  user: {
    profile: 'Perfil',
    logout: 'Salir',
    data: 'Datos Generales',
    secuirty: 'Seguridad',
  },
  permiso: {
    zonas: 'Zonas',
    moderations: 'Moderaciones',
    profils: 'Perfiles',
    ver: 'Ver',
    edit: 'Editar',
    create: 'Crear',
    delete: 'Eliminar',
    views: 'Vistas',
    tableros: 'Tableros',
    permiso: 'Permisos',
    roles: 'Roles',
    perfilUser: 'Perfiles de afiliados',
    usuarios: 'Usuarios',
    typeAfiliado: 'Tipo de afiliado',
    agente: 'Agentes',
    userRegister: 'Usuarios registrados',
    markups: 'Markups',
    configData: 'Configuración General',
    flights: 'Vuelos',
    contrate_flights: 'Contratos Vuelos',
    class: 'Clases',
    aeronave: 'Aeronaves',
    aerolinea: 'Aerolíneas',
    tarifa_flights: 'Tarifas',
    dimensiones: 'Dimensiones equipaje',
    pesos: 'Pesos equipaje',
    logs: 'Logs',
    cars: 'Autos',
    trasnmision: 'Transmisión',
    typeCar: 'Tipo de Auto',
    marca: 'Marca',
    modelo: 'Modelo',
    combustible: 'Combustible',
    caracteristicas: 'Características',
    category: 'Categorías',
    rentadora: 'Rentadora',
    oficinaRenta: 'Oficinas de renta',
    ptoRecogida: 'Punto de recogida',
    edadConductor: 'Edad del Conductor',
    tarifas: 'Tarifas',
  },
  Dashboards: 'Dashboards',
  CRM: 'CRM',
  Analytics: 'Analytics',
  eCommerce: 'eCommerce',
  Calendar: 'Calendar',
  Chat: 'Chat',
  Email: 'Email',
  Invoice: 'Invoice',
  List: 'List',
  Preview: 'Preview',
  Edit: 'Edit',
  Add: 'Add',
  User: 'User',
  'User List': 'User List',
  'User View': 'User View',
  'APPS AND PAGES': 'APPS AND PAGES',
  Pages: 'Pages',
  Authentication: 'Authentication',
  'Login V1': 'Login V1',
  'Login V2': 'Login V2',
  'Register V1': 'Register V1',
  'Register V2': 'Register V2',
  'Forgot Password V1': 'Forgot Password V1',
  'Forgot Password V2': 'Forgot Password V2',
  'Reset Password V1': 'Reset Password V1',
  'Reset Password V2': 'Reset Password V2',
  Miscellaneous: 'Miscellaneous',
  'Under Maintenance': 'Under Maintenance',
  Error: 'Error',
  'Coming Soon': 'Coming Soon',
  'Not Authorized': 'Not Authorized',
  'Knowledge Base': 'Knowledge Base',
  'Account Settings': 'Account Settings',
  Pricing: 'Pricing',
  FAQ: 'FAQ',
  'USER INTERFACE': 'USER INTERFACE',
  Typography: 'Typography',
  Icons: 'Icons',
  Gamification: 'Gamification',
  Cards: 'Cards',
  Basic: 'Basic',
  Statistics: 'Statistics',
  Advance: 'Advance',
  Actions: 'Actions',
  Chart: 'Chart',
  Components: 'Components',
  Alert: 'Alert',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Button: 'Button',
  Menu: 'Menu',
  'Expansion Panel': 'Expansion Panel',
  Dialog: 'Dialog',
  Pagination: 'Pagination',
  Tabs: 'Tabs',
  Tooltip: 'Tooltip',
  Chip: 'Chip',
  Snackbar: 'Snackbar',
  Stepper: 'Stepper',
  Timeline: 'Timeline',
  Treeview: 'Treeview',
  'FORMS & TABLES': 'FORMS & TABLES',
  'Forms Elements': 'Forms Elements',
  Autocomplete: 'Autocomplete',
  Checkbox: 'Checkbox',
  Combobox: 'Combobox',
  'File Input': 'File Input',
  Radio: 'Radio',
  'Range Slider': 'Range Slider',
  Select: 'Select',
  Slider: 'Slider',
  Switch: 'Switch',
  Textarea: 'Textarea',
  Textfield: 'Textfield',
  'Date Picker': 'Date Picker',
  'Month Picker': 'Month Picker',
  'Time Picker': 'Time Picker',
  Rating: 'Rating',
  'Form Layout': 'Form Layout',
  'Form validation': 'Form validation',
  Tables: 'Tables',
  'Simple Table': 'Simple Table',
  Datatable: 'Datatable',
  'CHARTS AND MAPS': 'CHARTS AND MAPS',
  Charts: 'Charts',
  'Apex Chart': 'Apex Chart',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Leaflet Maps',
  Others: 'Others',
  'Menu Levels': 'Menu Levels',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Disabled Menu': 'Disabled Menu',
  'Raise Support': 'Raise Support',
  Documentation: 'Documentation',
  'Charts & Maps': 'Charts & Maps',
  Apps: 'Apps',
  'User Interface': 'User Interface',
  'Forms & Tables': 'Forms & Tables',
}
