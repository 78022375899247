// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // AIRPORT
  {
    path: '/airport/list',
    name: 'airport-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/flights/Airport.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_airport',
    },
  },
  {
    path: '/airport/search',
    name: 'airport-search',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/flights/AirportSearch.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_airport_search',
    },
  },

  // AEROLINE
  {
    path: '/aeroline/list',
    name: 'aeroline-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/flights/Aeroline.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_aerolinea',
    },
  },

  // CLASS
  {
    path: '/class/list',
    name: 'class-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/flights/Class.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_class',
    },
  },
]

export default formTable
