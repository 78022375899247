export default {

  getAbility(ability) {
    const permisos = sessionStorage.getItem('permisos_auth')
    if (permisos === '*') {
      return true
    }
    if (permisos.includes(ability)) {
      return true
    }

    return false
  },
}
