// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CATEGORIES
  {
    path: '/categorias-hotels/list',
    name: 'categorias-hotels-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/hotels/Categories.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_categorias_hotels',
    },
  },

  // TYPE DESTINO
  {
    path: '/type-destination-hotels/list',
    name: 'type-destination-hotels-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/hotels/TypeDestino.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_tipo_destino_hotels',
    },
  },

  // HOST DESTINO
  {
    path: '/host-destination-hotels/list',
    name: 'host-destination-hotels-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/hotels/HostDestino.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_host_destination_hotels',
    },
  },

  // INTERESES
  {
    path: '/interests-hotels/list',
    name: 'interests-hotels-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/hotels/Intereses.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_intereses_hotels',
    },
  },

  // SERVICIOS
  {
    path: '/services-hotels/list',
    name: 'services-hotels-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/hotels/Servicios.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_servicios_hotels',
    },
  },
]

export default formTable
