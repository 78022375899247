export default {
  plataforma: {
    title: 'General data of the platform',
  },
  login: {
    title: 'Administration panel',
    subTitle: 'Please sign-in to your account',
    forgot: 'Forgot Password?',
    remember: 'Remember Me',
    newAccount: 'New on our platform?',
    createAccount: 'Create an account',
  },
  register: {
    title: 'Adventure starts here',
    subtitle: 'Make your app management easy!',
    exist: 'Already have an account?',
    btnIn: 'Login',
    btnUp: 'Register',
    titlePerson: 'General information',
    subtitlePerson: 'Contact informatio',
    namePerson: 'Name and surname',
    emailPerson: 'Email',
    codePais: 'Country code',
    noTelefo: 'Phone number',
    subtitleCompany: 'Company information',
    nameCompany: 'Company name',
    siteWebCompany: 'Website',
    titleFact: 'Billing Data',
    nameFact: 'Tax Name',
    cifNif: 'NIF/NIT',
    address: 'Address',
    codePostal: 'Postal Code',
    locality: 'Location',
    country: 'Country',
    profile: 'Choose your profiles',
    profileSub: 'Tell us a little about your Company',
    msgExistCompany: 'Sorry, you are already registered. Please contact your administrator.',
  },
  forgot: {
    title: 'Forgot Password?',
    subtitle: "Enter your email and we'll send you instructions to reset your password",
    btn: 'Send reset link',
    btnBack: 'Back to login',
    errorUserInex: 'We are sorry but we have not found {email} in our system.',
    errorUserInac: 'Sorry but {email} is not active in our system.',
    sendEmail: 'Notification has been sent to {email}. Follow the steps to recover your account',
  },
  reset: {
    title: 'Reset Password',
    subtitle: 'your new password must be different from previously used passwords',
    btn: 'Set New Password',
    btnBack: 'Back to login',
    msgSuccess: 'Password reset. Please login',
  },
  activeAcount: {
    title: 'Activate your account',
    subTitle: 'with these credentials you can manage your company',
    password: 'New Passwor',
    confirmPassword: 'Confirm Password',
    btn: 'Activate',
    msgInvalidToken: 'Your token is invalid',
    msgSuccess: 'User activated. Please login',
  },
  lbl: {
    list: 'List',
    disponibilidad: 'Availability',
    page: 'Page',
    perPage: 'Items per page',
    method: 'Type of request',
    params: 'Parameters',
    request: 'Request',
    response: 'Response',
    email: 'Email',
    password: 'Password',
    new_password: 'New Password',
    confirmation_password: 'Confirm Password',
    user: 'User',
    name: 'Name',
    apellidos: 'Surnames',
    changeFoto: 'Change photo',
    changePassword: 'Change Password',
    identificador: 'Identifier',
    color: 'Color',
    code: 'Code',
    slug: 'Slug',
    active: 'Active',
    showFront: 'Registry',
    description: 'Description',
    itemsPerPage: 'Per page',
    actions: 'Actions',
    rol: 'Role',
    permisoEdit: 'Update permission',
    perfil: 'Profile',
    typeProduct: 'Product Type',
    products: 'Products',
    pcFee: 'Percentage or Fee',
    pc: 'Percentage',
    fee: 'Value',
    infoAdd: 'Additional Information',
    info: 'Information',
    ventaDirectas: 'Direct sells',
    valor: 'Value',
    pc1: '%',
    all: 'All',
    company: 'Company',
    contacto: 'Contact',
    profiles: 'Profiles',
    dateSoliciti: 'Application date',
    manageSolic: 'Manage Approval',
    acceptSolic: 'Approve Request',
    denegSolic: 'Deny Request',
    address: 'Address',
    telefonos: 'Phones',
    telefono: 'Phone',
    horarios: 'Schedules',
    horario: 'Hours',
    from: 'From',
    apertura: 'Opening',
    cierre: 'Closing',
    to: 'To',
    ida: 'Departure',
    ida_vuelta: 'Departure and return',
    nultiple_destino: 'Multiple destinations',
    back: 'Return',
    longitud: 'Longitude',
    latitud: 'Latitude',
    showMap: 'Show Map',
    hideMap: 'Hide Map',
    airport: 'Airport',
    city: 'City',
    state: 'State',
    states: 'States',
    zip: 'Zip',
    country: 'Country',
    provinceEstado: 'Province / State',
    locality: 'Location',
    typeAfiliado: 'Affiliate Type',
    createAdmin: 'Create Admin',
    dataAdmin: 'Administrator Data',
    promover: 'To promote',
    dateVuelo: 'Flight date',
    dateArrival: 'Arrival date and time',
    ruta: 'Route',
    numeroVuelo: 'Flight number',
    operador: 'Operated by',
    vuelo: 'Flight',
    de: 'of',
    a: 'to',
    day: 'days',
    mas: 'more of',
    vuelos: 'flight',
    cars: 'cars',
    contratos: 'contracts',
    selectVuelo: 'Select flight',
    aeronave: 'Aircraft',

    // aerolinea: 'Airlines',
    origen: 'Origin',
    terminal: 'Terminal',
    puerta: 'Door',
    destino: 'Destination',
    destinos: 'Destinations',
    searchDestino: 'Search Destinations',
    text: 'Text',
    textSearch: 'String to search',
    iata: 'IATA',
    checkIn: 'Check In',
    hour_departure: 'Departure',
    departure: 'Departure',
    hour_arrival: 'Arrival',
    arrival: 'Arrival',
    durationVuelo: 'Flight Time',
    durationEscala: 'Duration Scale',
    durationEscalaRango: 'until {time} hours',
    durationViaje: 'Travel time',
    escala: 'scale',
    escalad: 'scales',
    escalas: 'Scales',
    escala1: 'All flights',
    escala2: 'Direct flight',
    escala3: 'Up to 1 scale',
    duration: 'Duration',
    origenSearch: 'What is the origin?',
    noResultSearchFlight: 'We did not find results for your search',
    hacia: 'To',
    destinoSearch: 'Where?',
    citySearch: 'Select the city',
    charter: 'Charter',
    flight: 'Flight',
    selectFlight: 'Select the flight',
    clases: 'Classes',
    clase: 'Class',
    cupo: 'Quotas',
    price_adult: 'Adult Price',
    price_min: 'Price',
    adl: 'Adl',
    adulto: 'Adult',
    adultos: 'Adults',
    childs: 'Childs',
    childsFlight: 'from 2 to 12',
    infants: 'Infants',
    infantsFlight: 'less than 2',
    mnr: 'Chl',
    menor: 'Child',
    infat: 'Infant',
    price_child: 'Price Children',
    price_infant: 'Infant Price',
    infant_free: 'Free Infant',
    equipaje_cabina: {
      title: 'Cabin baggage',
      dimensiones: 'Dimensions',
      peso: 'Weight',
      precio: 'Price',
      para: 'Applies to',
      adul: 'Adult',
      child: 'Child',
      inf: 'Infant',
    },
    equipaje_bodega: {
      title: 'Luggage in hold',
      dimensiones: 'Dimensions',
      addMaleta: 'Add Suitcase',
      peso: 'Weight',
      precioMaleta: 'Suitcase',
      max_exceso_equipaje: 'Maximum weight',
      exceso_equipaje: 'Excess baggage',
      tasa_despacho: 'Dispatch rate',
      para: 'Applies to',
      adul: 'Adult',
      child: 'Child',
      inf: 'Infant',
    },
    maleta_adicional: {
      title: 'Additional suitcase',
      dimensiones: 'Dimensions',
      peso: 'Weight',
      cant: 'Cant.',
      para: 'Applies to',
      adul: 'Adul.',
      child: 'Chil.',
      inf: 'Inf.',
    },
    precio_adicional: {
      title: 'Additional price',
      um: 'Measure unit',
      de: 'from',
      a: 'to',
      precio: 'Price',
      para: 'Applies to',
      adul: 'Adul.',
      child: 'Chil.',
      inf: 'Inf.',
    },
    comidas_bordo: {
      title: 'Meals on board',
      para: 'Applies to',
      adul: 'Adul.',
      child: 'Chil.',
      inf: 'Inf.',
    },
    reembolso: {
      title: 'Refunds',
      para: 'Applies to',
      adul: 'Adul.',
      child: 'Chil.',
      inf: 'Inf.',
    },
    cambio_fecha: {
      title: 'Date changes',
      para: 'Applies to',
      adul: 'Adul.',
      child: 'Chil.',
      inf: 'Inf.',
    },
    elec_asiento: {
      title: 'Choice of seats',
      para: 'Applies to',
      adul: 'Adul.',
      child: 'Chil.',
      inf: 'Inf.',
    },
    frecuency: 'Frequency',
    frecuencias: 'Frequencies',
    frecuenciasFlight: 'Flight frequencies',
    temporadas: 'Seasons',
    validez: 'Validity',
    vigencia: 'Validity',
    fromValidez: 'Valid from',
    toValidez: 'Valid until',
    dimension: 'Dimension',
    largo: 'Long',
    alto: 'High',
    ancho: 'Wide',
    tipo: 'Type',
    um: 'UM',
    lineal: 'Lineal',
    peso: 'Weight',
    pesoMax: 'Max.',
    fechasVuelo: 'Flight dates',
    todosDay: 'All',
    semanaDay: 'Weekdays',
    especifDay: 'Specific days',
    detailContrate: 'Contract Conditions',
    tarifa: 'Rates',
    conditionContrate: 'Tariff Policy',
    rulesTarifa: 'Fare Rules',
    otherTarifa: 'Other fees with luggage',
    date: 'Date',
    dateCreate: 'Creation date',
    car: 'Car',
    cantPuertas: 'Number of doors',
    cantPaxs: 'Number of seats',
    cantMaletas: 'Bags',
    small: 'Pequeñas',
    big: 'Grandes',
    umComb: 'UM',
    capacityComb: 'Fuel capacity',
    consumoComb: 'Fuel consumption',
    motor: 'Engine',
    publico: 'Published',
    sugerido: 'Suggested',
    searchImages: 'Search the images',
    galery: 'Gallery',
    details: 'Details',
    edadConductor: "Driver's age",
    proveedor: 'Provider',
    aerolinea: 'Airline',
    validFrom: 'Valid from',
    validTo: 'Valid until',
    change: 'Changes',
    dataOld: 'Old data',
    propuesta: 'Change Proposal',
    oficina: 'Office',
    time_completo: 'Open 24 hours',
    bag: 'Luggage',
    bagNoInclude: 'Baggage not included in the price',
    pieza: '{n} piece | {n} pieces',
    bagHand: 'Hand luggage',
    filtros: 'Filters',
    byMejorOpcion: 'Better option',
    byMasEconomico: 'More economical',
    byMasRapido: 'Faster',
    action: 'Action',
    recogida: 'Pickup',
    dateRecogida: 'Pick up date',
    entrega: 'Delivery',
    dateEntrega: 'Deliver date',
    recogidaEntrega: 'Pickup and Delivery',
    entregaDiffRecogida: 'Deliver to a different location',
    hotelDestinos: 'City, region or name of accommodation',
    dateIn: 'Entry date',
    fromAl: 'from the',
    dateOut: 'Departure date',
  },
  btn: {
    send: 'Send',
    apply: 'Apply',
    continue: 'Continue',
    search: 'Search',
    close: 'Close',
    cancele: 'Cancel',
    insert: 'Insert',
    generate: 'Trigger',
    create: 'Create',
    copy: 'Copy',
    update: 'Update',
    updatePermisos: 'Update Permission',
    edit: 'Editr',
    delete: 'Delete',
    clone: 'Clone',
    programation: 'Schedule',
    see: 'View',
    back: 'Back',
    import: 'Import',
    activar: 'Activate',
    desactivar: 'Deactivate',
    all: 'All',
    acceptChange: 'Accept Changes',
    canceleChange: 'Reject Changes',
    uploadLote: 'Batch upload',
  },
  msg: {
    error: {
      406: 'The request could not be completed. There is invalid data.',
    },
    dataLong: 'The search text must contain at least 3 characters.',
    dataRequiere: 'You must enter your data.',
    dataSpecific: 'You must specify a date range.',
    infoSuccess: 'Updated information.',
    fileUpload: 'File in process.',
    invalidCredentials: 'Invalid credentials.',
    userInactive: 'Inactive user',
    datasRequiere: 'You must fill in the required information.',
    noAutorice: 'You are not authorized to perform this operation.',
    dupliqueFrecuencia: 'Check your data, is duplicating rates to the contract.',
    notMatch: 'Passwords do not match.',
    deleteItem: 'Are you sure you want to delete {item}?',
    deleteMarkups: 'Are you sure you want to remove this Markup?',
    deleteFrecuencia: 'Are you sure you want to delete the frecuency {item}?',
    deleteFlight: 'Are you sure you want to delete the flight {item}?',
    errorDeleteFlight: 'No es posible eliminar el vuelo {item}. Existen contratos relacionados a este.',
    selectFlight: 'Whoops!!!... you must select a flight to continue.',
    noNumberFlight: 'Whoops!!!... flight not found {numero}.',
    userExist: 'There is already a user with the email {email}',
    airlineExist: 'An airline already exists with the identifier {identificador}',
    airplaneExist: 'An airplane already exists with the name {identificador}',
    airportExist: 'The airport already exists {identificador}',
    claseExist: 'An clase already exists with the name {identificador}',
    tarifaVueloExist: 'An rate already exists with the name {identificador}',
    dimensionExist: 'There is already a dimension of the type {identificador}',
    pesoExist: 'There is already a weight of the type {identificador}',
    transmisionExist: 'Transmission already exists {identificador}',
    typeAutoExist: 'Car type already exists {identificador}',
    typeAutoNote: 'The types or classifications of the car correspond to the automotive body.',
    marcaExist: 'The brand already exists {identificador}',
    modeloExist: 'The model already exists {identificador}',
    combustibleExist: 'Fuel already exists {identificador}',
    caracteristicaExist: 'Feature already exists {identificador}',
    categoryExist: 'Category already exists {identificador}',
    roleExist: 'Role already exists {identificador}',
    rentadoraExist: 'The renter already exists {identificador}',
    tarifaExist: 'The fee already exists {identificador}',
    tarifaMaxExist: 'If we leave the maximum number of days blank, it will be taken as unlimited, eg: +{identificador}',
    edadMaxExist: 'If we leave the maximum number of age blank, it will be taken as unlimited, eg: +{identificador}',
    sendEmail: 'Notification has been sent to {email}',
    existFlight:
      'Whoops!!!... there is already a registered flight with the number {numero}. Please check that you have entered the data correctly. If necessary, go to the list of available flights to edit it.',
    existCars:
      'Whoops!!!... there is already a registered car with the name {name}. Please check that you have entered the data correctly. If necessary, go to the list of available flights to edit it.',

    confirmChange: 'For your changes to take effect you need to re-login. Do you wish to continue?',
    emptySeason: 'Whoops!!!... must define at least one season.',
    activeUser:
      'Are you sure you want to activate user {email}? This action will notify the user to create their profile.',
    desactiveUser:
      'Are you sure you want to disable the user {email}? This action will not allow you to enter the platform until it is activated again.',

    noElementShow: 'No items found.',
    acceptChange: 'Are you sure you want to accept the new changes?',
    rejectChange: 'Are you sure you want to reject the new changes?',

    verifyTarifa: 'Check the fare rules to find out if changes are allowed.',
    verifyReembolso: 'See fare rules for refund policy.',
    noResultFlightSearch: 'Whoops!!!... no results were found for your search criteria.',

    copyText: 'Has been copied {text}',
    noAsociedCompany: 'No company associated with this user was found.',
    successToken: 'Tokens generated successfully.',
    generateToken:
      'Are you sure you want to reset your tokens? This action will break any links from external apps that make use of your current tokens.',
    apiText1:
      'API keys identify the project (the APP or the website) that makes the call to the API itself. Authentication tokens identify the user (person) using the APP or website.',
    apiText2:
      'It is very important that you know that whoever has your API Key will have access to your account, so you have to be extremely careful with it.',

    noResultFlightCotizacionTitle: 'Change search parameters',
    noResultFlightCotizacionBody: 'Try searching for flights on other dates or from different airports',
  },
  menu: {
    dashboard: 'Dashboard',
    subheader: 'TOOLS',
    acceso: 'Access',
    booking: 'Booking',
    comitions: 'Commissions',
    contability: 'Accounting',
    permisos: 'Accession',
    cotizador: 'Quoter',
    reservas: 'Bookings',
    nomenclador: 'Nomenclators',
    typeAfiliado: 'Affiliate Type',
    roles: 'Rols',
    perfilUser: 'Profiles',
    users: 'Users',
    usersSon: 'Child Users',
    usersPropio: 'Own',
    usersAdmin: 'Administrator',
    userRegister: 'Affiliates',
    companies: 'Companies',
    agentes: 'Agents',
    operations: 'Operations',
    logs: 'Logs',
    markups: 'Markups',
    administration: 'Administration',
    configuration: 'Setting',
    dataGeneral: 'Identifier',
    jsonAirport: 'JSON Airport',
    products: 'Products',
    flights: 'Flights',
    cars: 'Cars',
    hotels: 'Hotels',
    contratos: 'Contracts',
    class: 'Classes',
    tarifas: 'Rates',
    aeronave: 'Aircraft',
    airport: 'Airports',
    airports: 'Airports',
    searchAirport: 'Search Airports',
    aerolinea: 'Airlines',
    dimensiones: 'Luggage dimensions',
    pesos: 'Luggage weights',
    cardFidelidad: 'Loyalty cards',
    codeOFAC: 'OFAC codes',
    trasnmision: 'Transmission',
    typeCar: 'Body Type',
    marca: 'Brand',
    modelo: 'Model',
    combustible: 'Fuel',
    caracteristicas: 'Features',
    category: 'Categories',
    rentadora: 'Rental company',
    oficinaRenta: 'Rental point',
    ptoRecogida: 'Pick up point',
    typeDestination: 'Type of destinations',
    intereses: 'Interests',
    servicios: 'Services and Facilities',
  },
  rules: {
    min6: 'At least 6 characters',
    upload1MB: 'Avatar size should be less than 1 MB!',
  },
  footer: {
    allRights: 'All rights Reserved',
    powered: 'Powered by',
  },
  user: {
    profile: 'Profile',
    logout: 'Logout',
    data: 'General Data',
    security: 'Security',
  },
  permiso: {
    zonas: 'Zones',
    moderations: 'Moderations',
    profils: 'Profiles',
    ver: 'view',
    edit: 'Edit',
    create: 'Create',
    delete: 'Delete',
    views: 'Views',
    tableros: 'dashboards',
    permiso: 'Permissions',
    roles: 'Rols',
    perfilUser: 'Affiliate profiles',
    usuarios: 'Users',
    typeAfiliado: 'Affiliate Type',
    agente: 'Agents',
    userRegister: 'Registered users',
    markups: 'Markups',
    configData: 'General configuration',
    flights: 'Flights',
    contrate_flights: 'Contracts Flights',
    class: 'Classes',
    aeronave: 'Aircraft',
    aerolinea: 'Airlines',
    tarifa_flights: 'Rates',
    dimensiones: 'Luggage dimensions',
    pesos: 'Luggage weights',
    logs: 'Logs',
    cars: 'Cars',
    trasnmision: 'Transmission',
    typeCar: 'Car type',
    marca: 'Brand',
    modelo: 'Model',
    combustible: 'Fuel',
    caracteristicas: 'Features',
    category: 'Categories',
    rentadora: 'Rental company',
    oficinaRenta: 'Rental point',
    ptoRecogida: 'Pick up point',
    edadConductor: "Driver's age",
    tarifas: 'Car Day Range',
  },
  Dashboards: 'Dashboards',
  CRM: 'CRM',
  Analytics: 'Analytics',
  eCommerce: 'eCommerce',
  Calendar: 'Calendar',
  Chat: 'Chat',
  Email: 'Email',
  Invoice: 'Invoice',
  List: 'List',
  Preview: 'Preview',
  Edit: 'Edit',
  Add: 'Add',
  User: 'User',
  'User List': 'User List',
  'User View': 'User View',
  'APPS AND PAGES': 'APPS AND PAGES',
  Pages: 'Pages',
  Authentication: 'Authentication',
  'Login V1': 'Login V1',
  'Login V2': 'Login V2',
  'Register V1': 'Register V1',
  'Register V2': 'Register V2',
  'Forgot Password V1': 'Forgot Password V1',
  'Forgot Password V2': 'Forgot Password V2',
  'Reset Password V1': 'Reset Password V1',
  'Reset Password V2': 'Reset Password V2',
  Miscellaneous: 'Miscellaneous',
  'Under Maintenance': 'Under Maintenance',
  Error: 'Error',
  'Coming Soon': 'Coming Soon',
  'Not Authorized': 'Not Authorized',
  'Knowledge Base': 'Knowledge Base',
  'Account Settings': 'Account Settings',
  Pricing: 'Pricing',
  FAQ: 'FAQ',
  'USER INTERFACE': 'USER INTERFACE',
  Typography: 'Typography',
  Icons: 'Icons',
  Gamification: 'Gamification',
  Cards: 'Cards',
  Basic: 'Basic',
  Statistics: 'Statistics',
  Advance: 'Advance',
  Actions: 'Actions',
  Chart: 'Chart',
  Components: 'Components',
  Alert: 'Alert',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Button: 'Button',
  Menu: 'Menu',
  'Expansion Panel': 'Expansion Panel',
  Dialog: 'Dialog',
  Pagination: 'Pagination',
  Tabs: 'Tabs',
  Tooltip: 'Tooltip',
  Chip: 'Chip',
  Snackbar: 'Snackbar',
  Stepper: 'Stepper',
  Timeline: 'Timeline',
  Treeview: 'Treeview',
  'FORMS & TABLES': 'FORMS & TABLES',
  'Forms Elements': 'Forms Elements',
  Autocomplete: 'Autocomplete',
  Checkbox: 'Checkbox',
  Combobox: 'Combobox',
  'File Input': 'File Input',
  Radio: 'Radio',
  'Range Slider': 'Range Slider',
  Select: 'Select',
  Slider: 'Slider',
  Switch: 'Switch',
  Textarea: 'Textarea',
  Textfield: 'Textfield',
  'Date Picker': 'Date Picker',
  'Month Picker': 'Month Picker',
  'Time Picker': 'Time Picker',
  Rating: 'Rating',
  'Form Layout': 'Form Layout',
  'Form validation': 'Form validation',
  Tables: 'Tables',
  'Simple Table': 'Simple Table',
  Datatable: 'Datatable',
  'CHARTS AND MAPS': 'CHARTS AND MAPS',
  Charts: 'Charts',
  'Apex Chart': 'Apex Chart',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Leaflet Maps',
  Others: 'Others',
  'Menu Levels': 'Menu Levels',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Disabled Menu': 'Disabled Menu',
  'Raise Support': 'Raise Support',
  Documentation: 'Documentation',
  'Charts & Maps': 'Charts & Maps',
  Apps: 'Apps',
  'User Interface': 'User Interface',
  'Forms & Tables': 'Forms & Tables',
}
