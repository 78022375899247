// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/cars/list',
    name: 'cars-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/cars/List.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'car_list',
    },
  },
  {
    path: '/cars/availability',
    name: 'cars-availability',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/cars/Availability.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'car_availability',
    },
  },
]

export default formTable
