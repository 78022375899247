/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable no-lonely-if */
// eslint-disable-next-line import/no-unresolved
import router from '@/router'
import axios from 'axios'
import { mdiHomeOutline } from '@mdi/js'

export default {
  logout() {
    sessionStorage.clear()
    router.push({ name: 'auth-login' })
  },
  GET_ABILITY({ ability }) {
    const permisos = sessionStorage.getItem('permisos_auth')
    if (permisos === '*') {
      return true
    }
    if (permisos.includes(ability)) {
      return true
    }

    return false
  },

  // ACTUALIZAR LOS PERMISOS DEL ROL O PERFIL AFILIADO
  getPerfilesAuth: state => {
    axios
      .get(`${process.env.VUE_APP_API_URL}profile`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
        },
      })
      .then(response => {
        // console.log(response.data.data.perfiles)
        // eslint-disable-next-line no-empty
        if (response.data.success === false) {
        } else {
          // console.log(response.data.data)
          state.userProfile = response.data.data
          state.perfilesAll = []
          state.perfilesAuth = []

          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < response.data.data.tableros.length; index++) {
            const element = response.data.data.tableros[index]
            if (element.active) {
              state.perfilesAuth.push({
                title: element.name,
                icon: mdiHomeOutline,
                to: { name: 'dashboard', params: { perfil: element.slug } },
                slug: element.slug,
                permiso: '',
                active: sessionStorage.getItem('perfil') === element.slug,
              })

              if (sessionStorage.getItem('perfil') === element.slug) {
                if (response.data.data.id_father === null) {
                  sessionStorage.setItem('permisos_auth', element.permisos.abilities)
                } else {
                  sessionStorage.setItem('permisos_auth', state.userProfile.role.permisos.abilities)
                }
              }
            }
          }
        }
      })
      .catch(error => console.log(error))
  },

  verifyAllPermisosItem: (state, pos) => {
    const { frecuencias } = state

    frecuencias.push(frecuencias[pos])
  },

  // ACTUALIZAR EL ESTADO DEL PERFIL
  updatePerfilesAuth: (state, itemActive) => {
    state.perfilesAuth.forEach(element => {
      if (element.slug !== itemActive.slug) {
        // eslint-disable-next-line no-param-reassign
        element.active = false
      } else {
        // eslint-disable-next-line no-param-reassign
        element.active = true
      }
    })

    // console.log(state.perfilesAuth)
    // console.log(itemActive)
  },

  // ACTUALIZAR LOS PERMISOS DEL ROL O PERFIL AFILIADO
  editPermisosItem: (state, permisosItem) => {
    state.permisosItem = permisosItem
  },

  // EDITAR LA INFORMACION DE LA VISTA DE PERMISOS
  setPermisosTab: (state, view) => {
    state.sourcePermisos = []
    state.viewPermisos = view
  },
  setPermisosTabLoading: (state, status) => {
    state.isLoadingPagePermisos = status
  },
  verifyPermisoZona: (state, pos) => {
    // BOOKING
    if (state.sourcePermisos[pos].permisos.includes('cotizador:list')
      && state.sourcePermisos[pos].permisos.includes('reservas:list')) {
      state.sourcePermisos[pos].all.checkboxBookingVer = true
    }
    if (state.sourcePermisos[pos].permisos.includes('cotizador:edit')
      && state.sourcePermisos[pos].permisos.includes('reservas:edit')) {
      state.sourcePermisos[pos].all.checkboxBookingEdit = true
    }
    if (state.sourcePermisos[pos].permisos.includes('cotizador:create')
      && state.sourcePermisos[pos].permisos.includes('reservas:create')) {
      state.sourcePermisos[pos].all.checkboxBookingCreate = true
    }
    if (state.sourcePermisos[pos].permisos.includes('cotizador:destroy')
      && state.sourcePermisos[pos].permisos.includes('reservas:destroy')) {
      state.sourcePermisos[pos].all.checkboxBookingDelete = true
    }

    // PRODUCTS
    if (state.sourcePermisos[pos].permisos.includes('flight:list')
      && state.sourcePermisos[pos].permisos.includes('hotel:list')
      && state.sourcePermisos[pos].permisos.includes('car:list')) {
      state.sourcePermisos[pos].all.checkboxProductsVer = true
    }
    if (state.sourcePermisos[pos].permisos.includes('flight:edit')
      && state.sourcePermisos[pos].permisos.includes('hotel:edit')
      && state.sourcePermisos[pos].permisos.includes('car:edit')) {
      state.sourcePermisos[pos].all.checkboxProductsEdit = true
    }
    if (state.sourcePermisos[pos].permisos.includes('flight:create')
      && state.sourcePermisos[pos].permisos.includes('hotel:create')
      && state.sourcePermisos[pos].permisos.includes('car:create')) {
      state.sourcePermisos[pos].all.checkboxProductsCreate = true
    }
    if (state.sourcePermisos[pos].permisos.includes('flight:destroy')
      && state.sourcePermisos[pos].permisos.includes('hotel:destroy')
      && state.sourcePermisos[pos].permisos.includes('car:destroy')) {
      state.sourcePermisos[pos].all.checkboxProductsDelete = true
    }

    // CONTRATOS
    if (state.sourcePermisos[pos].permisos.includes('contrate_flights:list')
      && state.sourcePermisos[pos].permisos.includes('contrate_hotels:list')
      && state.sourcePermisos[pos].permisos.includes('contrate_cars:list')) {
      state.sourcePermisos[pos].all.checkboxContrateVer = true
    }
    if (state.sourcePermisos[pos].permisos.includes('contrate_flights:edit')
      && state.sourcePermisos[pos].permisos.includes('contrate_hotels:edit')
      && state.sourcePermisos[pos].permisos.includes('contrate_cars:edit')) {
      state.sourcePermisos[pos].all.checkboxContrateEdit = true
    }
    if (state.sourcePermisos[pos].permisos.includes('contrate_flights:create')
      && state.sourcePermisos[pos].permisos.includes('contrate_hotels:create')
      && state.sourcePermisos[pos].permisos.includes('contrate_cars:create')) {
      state.sourcePermisos[pos].all.checkboxContrateCreate = true
    }
    if (state.sourcePermisos[pos].permisos.includes('contrate_flights:destroy')
      && state.sourcePermisos[pos].permisos.includes('contrate_hotels:destroy')
      && state.sourcePermisos[pos].permisos.includes('contrate_cars:destroy')) {
      state.sourcePermisos[pos].all.checkboxContrateDelete = true
    }

    // ADMINISTRATION
    if (state.sourcePermisos[pos].permisos.includes('contabilidad:list')
      && state.sourcePermisos[pos].permisos.includes('comisiones:list')
      && state.sourcePermisos[pos].permisos.includes('markup:list')) {
      state.sourcePermisos[pos].all.checkboxAdministrationVer = true
    }
    if (state.sourcePermisos[pos].permisos.includes('contabilidad:edit')
      && state.sourcePermisos[pos].permisos.includes('comisiones:edit')
      && state.sourcePermisos[pos].permisos.includes('markup:edit')) {
      state.sourcePermisos[pos].all.checkboxAdministrationEdit = true
    }
    if (state.sourcePermisos[pos].permisos.includes('contabilidad:create')
      && state.sourcePermisos[pos].permisos.includes('comisiones:create')
      && state.sourcePermisos[pos].permisos.includes('markup:create')) {
      state.sourcePermisos[pos].all.checkboxAdministrationCreate = true
    }
    if (state.sourcePermisos[pos].permisos.includes('contabilidad:destroy')
      && state.sourcePermisos[pos].permisos.includes('comisiones:destroy')
      && state.sourcePermisos[pos].permisos.includes('markup:destroy')) {
      state.sourcePermisos[pos].all.checkboxAdministrationDelete = true
    }

    // CONFIGURATION
    if (state.sourcePermisos[pos].permisos.includes('config_general:list')
      && state.sourcePermisos[pos].permisos.includes('api_keys:list')
      && state.sourcePermisos[pos].permisos.includes('perfil_afiliado:list')
      && state.sourcePermisos[pos].permisos.includes('user_register:list')
      && state.sourcePermisos[pos].permisos.includes('role:list')
      && state.sourcePermisos[pos].permisos.includes('user:list')
      && state.sourcePermisos[pos].permisos.includes('users_son:list')
      && state.sourcePermisos[pos].permisos.includes('agente:list')) {
      state.sourcePermisos[pos].all.checkboxConfigurationVer = true
    }
    if (state.sourcePermisos[pos].permisos.includes('config_general:edit')
      && state.sourcePermisos[pos].permisos.includes('api_keys:edit')
      && state.sourcePermisos[pos].permisos.includes('perfil_afiliado:edit')
      && state.sourcePermisos[pos].permisos.includes('user_register:edit')
      && state.sourcePermisos[pos].permisos.includes('role:edit')
      && state.sourcePermisos[pos].permisos.includes('user:edit')
      && state.sourcePermisos[pos].permisos.includes('users_son:edit')
      && state.sourcePermisos[pos].permisos.includes('agente:edit')) {
      state.sourcePermisos[pos].all.checkboxConfigurationEdit = true
    }
    if (state.sourcePermisos[pos].permisos.includes('config_general:create')
      && state.sourcePermisos[pos].permisos.includes('api_keys:create')
      && state.sourcePermisos[pos].permisos.includes('perfil_afiliado:create')
      && state.sourcePermisos[pos].permisos.includes('user_register:create')
      && state.sourcePermisos[pos].permisos.includes('role:create')
      && state.sourcePermisos[pos].permisos.includes('user:create')
      && state.sourcePermisos[pos].permisos.includes('users_son:create')
      && state.sourcePermisos[pos].permisos.includes('agente:create')) {
      state.sourcePermisos[pos].all.checkboxConfigurationCreate = true
    }
    if (state.sourcePermisos[pos].permisos.includes('config_general:destroy')
      && state.sourcePermisos[pos].permisos.includes('api_keys:destroy')
      && state.sourcePermisos[pos].permisos.includes('perfil_afiliado:destroy')
      && state.sourcePermisos[pos].permisos.includes('user_register:destroy')
      && state.sourcePermisos[pos].permisos.includes('role:destroy')
      && state.sourcePermisos[pos].permisos.includes('user:destroy')
      && state.sourcePermisos[pos].permisos.includes('users_son:destroy')
      && state.sourcePermisos[pos].permisos.includes('agente:destroy')) {
      state.sourcePermisos[pos].all.checkboxConfigurationDelete = true
    }

    // NOMENCLADORES VUELOS
    if (state.sourcePermisos[pos].permisos.includes('nom_aerolines:list')
      && state.sourcePermisos[pos].permisos.includes('airplane:list')
      && state.sourcePermisos[pos].permisos.includes('nom_clase_flights:list')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifa_flights:list')
      && state.sourcePermisos[pos].permisos.includes('nom_dimensiones_flights:list')
      && state.sourcePermisos[pos].permisos.includes('nom_peso_equipaje_flights:list')
      && state.sourcePermisos[pos].permisos.includes('airport:list')
      && state.sourcePermisos[pos].permisos.includes('nom_tarjeta_fidelidad_flights:list')
      && state.sourcePermisos[pos].permisos.includes('nom_code_ofac_flights:list')) {
      state.sourcePermisos[pos].all.checkboxNomencladoresFlightVer = true
    }
    if (state.sourcePermisos[pos].permisos.includes('nom_aerolines:edit')
      && state.sourcePermisos[pos].permisos.includes('airplane:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_clase_flights:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifa_flights:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_dimensiones_flights:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_peso_equipaje_flights:edit')
      && state.sourcePermisos[pos].permisos.includes('airport:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_tarjeta_fidelidad_flights:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_code_ofac_flights:edit')) {
      state.sourcePermisos[pos].all.checkboxNomencladoresFlightEdit = true
    }
    if (state.sourcePermisos[pos].permisos.includes('nom_aerolines:create')
      && state.sourcePermisos[pos].permisos.includes('airplane:create')
      && state.sourcePermisos[pos].permisos.includes('nom_clase_flights:create')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifa_flights:create')
      && state.sourcePermisos[pos].permisos.includes('nom_dimensiones_flights:create')
      && state.sourcePermisos[pos].permisos.includes('nom_peso_equipaje_flights:create')
      && state.sourcePermisos[pos].permisos.includes('airport:create')
      && state.sourcePermisos[pos].permisos.includes('nom_tarjeta_fidelidad_flights:create')
      && state.sourcePermisos[pos].permisos.includes('nom_code_ofac_flights:create')) {
      state.sourcePermisos[pos].all.checkboxNomencladoresFlightCreate = true
    }
    if (state.sourcePermisos[pos].permisos.includes('nom_aerolines:destroy')
      && state.sourcePermisos[pos].permisos.includes('airplane:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_clase_flights:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifa_flights:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_dimensiones_flights:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_peso_equipaje_flights:destroy')
      && state.sourcePermisos[pos].permisos.includes('airport:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_tarjeta_fidelidad_flights:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_code_ofac_flights:destroy')) {
      state.sourcePermisos[pos].all.checkboxNomencladoresFlightDelete = true
    }

    // NOMENCLADORES AUTOS
    if (state.sourcePermisos[pos].permisos.includes('nom_transmision_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_type_auto_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_marca_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_model_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_combustible_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_caracteristica_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_rentadora_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifas_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_edades_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_tags_pto_recogida_cars:list')
      && state.sourcePermisos[pos].permisos.includes('nom_oficina_renta_cars:list')) {
      state.sourcePermisos[pos].all.checkboxNomencladoresCarsVer = true
    }
    if (state.sourcePermisos[pos].permisos.includes('nom_transmision_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_type_auto_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_marca_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_model_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_combustible_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_caracteristica_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_rentadora_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifas_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_edades_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_tags_pto_recogida_cars:edit')
      && state.sourcePermisos[pos].permisos.includes('nom_oficina_renta_cars:edit')) {
      state.sourcePermisos[pos].all.checkboxNomencladoresCarsEdit = true
    }
    if (state.sourcePermisos[pos].permisos.includes('nom_transmision_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_type_auto_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_marca_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_model_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_combustible_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_caracteristica_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_rentadora_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifas_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_edades_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_tags_pto_recogida_cars:create')
      && state.sourcePermisos[pos].permisos.includes('nom_oficina_renta_cars:create')) {
      state.sourcePermisos[pos].all.checkboxNomencladoresCarsCreate = true
    }
    if (state.sourcePermisos[pos].permisos.includes('nom_transmision_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_type_auto_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_marca_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_model_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_combustible_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_caracteristica_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_categories_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_rentadora_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_tarifas_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_edades_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_tags_pto_recogida_cars:destroy')
      && state.sourcePermisos[pos].permisos.includes('nom_oficina_renta_cars:destroy')) {
      state.sourcePermisos[pos].all.checkboxNomencladoresCarsDelete = true
    }

    // NOMENCLADORES ALL
    if (state.sourcePermisos[pos].all.checkboxNomencladoresFlightVer
      && state.sourcePermisos[pos].all.checkboxNomencladoresCarsVer
      && state.sourcePermisos[pos].permisos.includes('tipo_afiliado:list')) {
      state.sourcePermisos[pos].all.checkboxNomencladoresVer = true
    }
    if (state.sourcePermisos[pos].all.checkboxNomencladoresFlightEdit
      && state.sourcePermisos[pos].all.checkboxNomencladoresCarsEdit
      && state.sourcePermisos[pos].permisos.includes('tipo_afiliado:edit')) {
      state.sourcePermisos[pos].all.checkboxNomencladoresEdit = true
    }
    if (state.sourcePermisos[pos].all.checkboxNomencladoresFlightCreate
      && state.sourcePermisos[pos].all.checkboxNomencladoresCarsCreate
      && state.sourcePermisos[pos].permisos.includes('tipo_afiliado:create')) {
      state.sourcePermisos[pos].all.checkboxNomencladoresCreate = true
    }
    if (state.sourcePermisos[pos].all.checkboxNomencladoresFlightDelete
      && state.sourcePermisos[pos].all.checkboxNomencladoresCarsDelete
      && state.sourcePermisos[pos].permisos.includes('tipo_afiliado:destroy')) {
      state.sourcePermisos[pos].all.checkboxNomencladoresDelete = true
    }
  },

  // USERS
  setSearchUsers: (state, items) => {
    state.searchUsers = items
  },
  setUserUpdate: (state, item) => {
    state.userUpdate = item
  },
  setUserUpdateAvatar: (state, item) => {
    state.userUpdateAvatar = item
  },
  setUserOnlyShow: (state, status) => {
    state.userOnlyShow = status
  },
  setUserItemsPerPage: (state, cant) => {
    state.userItemsPerPage = cant
  },
  setTotalUsersFilters: (state, total) => {
    state.totalUsersFilters = total
  },
  setTotalUsersAll: (state, total) => {
    state.totalUsersAll = total
  },

  // OFICINA RENTA
  // AIRPORT
  updateOficinaRenta: (state, oficinaRenta) => {
    state.oficinaRenta.country = oficinaRenta.country
    state.oficinaRenta.state = oficinaRenta.state
    state.oficinaRenta.city = oficinaRenta.city
  },
  editOficinaRenta: (state, oficce) => {
    state.oficinaRenta = oficce
  },

  // AIRPORT
  updateAirport: (state, aiport) => {
    state.airport.country = aiport.country
    state.airport.state = aiport.state
    state.airport.city = aiport.city
  },
  editAirport: (state, aiport) => {
    state.airport = aiport
  },
  setOnlyShow: (state, status) => {
    state.onlyShow = status
  },
  resetAirport: state => {
    state.airport = {
      iata: null,
      name: null,
      country: null,
      state: null,
      city: null,
      direccion: null,
    }
  },
  resetOficinaRenta: state => {
    state.oficinaRenta = {
      name: null,
      ptos_recogida: null,
      country: null,
      state: null,
      city: null,
      direccion: null,
    }
  },
  resetGeoTag: state => {
    state.geotag = {
      longitud: null,
      latitud: null,
    }
  },

  // COTIZADOR FLIGHTS
  addDestinoCotizadorFligth: state => {
    state.destinosCotizadorFlight.push({
      origen: null,
      destino: null,
      from: null,
      to: null,
    })
  },
  resetCotizadorFligth: state => {
    state.cotizadorFlight = {
      cant_adults: 1,
      cant_ninnos: 0,
      cant_infant: 0,
      aerolinea: 0,
      onlyIda: false,
      destinosMultiples: false,
    }
  },
  deleteDestinoCotizadorFligth: (state, pos) => {
    const { destinosCotizadorFlight } = state

    destinosCotizadorFlight.splice(pos, 1)
  },
  resetDestinoCotizadorFligth: state => {
    const { destinosCotizadorFlight } = state

    destinosCotizadorFlight.splice(1, destinosCotizadorFlight.length)
  },
  emptyDestinoCotizadorFligth: state => {
    state.destinosCotizadorFlight = [
      {
        origen: null,
        destino: null,
        from: null,
        to: null,
      },
    ]
  },
  incrementAdultosCotizadorFligth: state => {
    // eslint-disable-next-line no-plusplus
    state.cotizadorFlight.cant_adults++
  },
  decrementAdultosCotizadorFligth: state => {
    // eslint-disable-next-line no-plusplus
    if ((state.cotizadorFlight.cant_ninnos > 0 || state.cotizadorFlight.cant_adults > 1) && state.cotizadorFlight.cant_adults > 0) {
      state.cotizadorFlight.cant_adults--
    }
  },
  incrementChildCotizadorFligth: state => {
    // eslint-disable-next-line no-plusplus
    state.cotizadorFlight.cant_ninnos++
  },
  decrementChildCotizadorFligth: state => {
    // eslint-disable-next-line no-plusplus
    if ((state.cotizadorFlight.cant_adults > 0 || state.cotizadorFlight.cant_ninnos > 1) && state.cotizadorFlight.cant_ninnos > 0) {
      state.cotizadorFlight.cant_ninnos--
    }
  },
  incrementInfantCotizadorFligth: state => {
    // eslint-disable-next-line no-plusplus
    state.cotizadorFlight.cant_infant++
  },
  decrementInfantCotizadorFligth: state => {
    // eslint-disable-next-line no-plusplus
    if (state.cotizadorFlight.cant_infant > 0) {
      state.cotizadorFlight.cant_infant--
    }
  },

  // RESULT
  setResultsCotizadorIdaFligth: (state, items) => {
    state.resultCotizadorIdaFlight = items
  },
  setAerolineCotizadorIdaFligth: (state, items) => {
    state.aerolineCotizadorIdaFlight = items
  },
  setIndexTarifaEconCotizadorIdaFligth: (state, { indAero, index }) => {
    state.aerolineCotizadorIdaFlight[indAero].indexTarifa = index
  },
  setAerolineCotizadorRegresoFligth: (state, items) => {
    state.aerolineCotizadorRegresoFlight = items
  },
  setIndexTarifaIdaEconCotizadorIdaBackFligth: (state, { indAero, index }) => {
    state.aerolineCotizadorRegresoFlight[indAero].indexTarifaIda = index
  },
  setIndexTarifaBackEconCotizadorIdaBackFligth: (state, { indAero, index }) => {
    state.aerolineCotizadorRegresoFlight[indAero].indexTarifaRegreso = index
  },
  setAerolineCotizadorDestinosFligth: (state, items) => {
    state.aerolineCotizadorDestinosFlight = items
  },
  setIndexTarifaEconCotizadorIdaDestinosFligth: (state, { indAero, indDestino, index }) => {
    state.aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].indexTarifa = index
  },

  setSearchingFlightCotizador: (state, valor) => {
    state.searchingFlightCotizador = valor
  },

  // SCHEDULE
  setScheduleResult: (state, items) => {
    state.scheduleResult = items
  },
  setSchedulePageResult: (state, page) => {
    state.pageResult = page
  },
  setScheduleItemsPerPageResult: (state, cant) => {
    state.itemsPerPageResult = cant
  },

  // FILTROS COTIZADOR FLIGHT
  setFiltrosCotizadorFlight: (state, item) => {
    if (item.aerolinea !== undefined && item.aerolinea !== null) {
      state.filtrosCotizadorFlight.aerolinea = item.aerolinea
    }
    if (item.escala !== undefined && item.escala !== null) {
      state.filtrosCotizadorFlight.escala = item.escala
    }
    if (item.duracionEscala !== undefined && item.duracionEscala !== null) {
      state.filtrosCotizadorFlight.duracionEscala = item.duracionEscala
    }
    if (item.duracionViaje !== undefined && item.duracionViaje !== null) {
      state.filtrosCotizadorFlight.duracionViaje = item.duracionViaje
    }
    if (item.frecuencias !== undefined && item.frecuencias !== null) {
      state.filtrosCotizadorFlight.frecuencias = item.frecuencias
    }
    if (item.airport !== undefined && item.airport !== null) {
      state.filtrosCotizadorFlight.airport = item.airport
    }
    if (item.clase !== undefined && item.clase !== null) {
      state.filtrosCotizadorFlight.clase = item.clase
    }
    if (item.equipaje !== undefined && item.equipaje !== null) {
      state.filtrosCotizadorFlight.equipaje = item.equipaje
    }
    if (item.byMejorOpcion !== undefined && item.byMejorOpcion !== null) {
      state.filtrosCotizadorFlight.byMejorOpcion = item.byMejorOpcion
    }
    if (item.byMasEconomico !== undefined && item.byMasEconomico !== null) {
      state.filtrosCotizadorFlight.byMasEconomico = item.byMasEconomico
    }
    if (item.byMasRapido !== undefined && item.byMasRapido !== null) {
      state.filtrosCotizadorFlight.byMasRapido = item.byMasRapido
    }
  },

  // ESCALAS
  adicionarEscala: state => {
    state.escalas.push({
      city: '',
      id: 0,
      duration_h: 0,
      duration_m: 0,
    })
  },
  updateEscala: (state, escalas) => {
    state.escalas = escalas
  },
  deleteEscala: (state, escala) => {
    state.loadEscalas = true
    const { escalas } = state

    state.escalas = escalas.filter(geeks => geeks.city.id !== escala.city.id)
    setTimeout(() => {
      state.loadEscalas = false
    }, 100)
  },

  // FRECUENCIAS
  adicionarFrecuencia: state => {
    if (state.frecuencias.length > 0) {
      state.frecuencias.push({
        frecuencia: [],
        fromValidez: state.frecuencias[state.frecuencias.length - 1].fromValidez,
        toValidez: state.frecuencias[state.frecuencias.length - 1].toValidez,
        check_in: '',
        hour_departure: '',
        terminal_departure: '',
        puerta_departure: '',
        hour_arrival: '',
        terminal_arrival: '',
        puerta_arrival: '',
        time_vuelo_h: state.frecuencias[state.frecuencias.length - 1].time_vuelo_h,
        time_vuelo_m: state.frecuencias[state.frecuencias.length - 1].time_vuelo_m,
      })
    } else {
      state.frecuencias.push({
        frecuencia: [],
        fromValidez: '',
        toValidez: '',
        check_in: '',
        hour_departure: '',
        terminal_departure: '',
        puerta_departure: '',
        hour_arrival: '',
        terminal_arrival: '',
        puerta_arrival: '',
        time_vuelo_h: 0,
        time_vuelo_m: 0,
      })
    }
  },
  updateFrecuencia: (state, frecuencias) => {
    state.frecuencias = frecuencias
  },
  cloneFrecuencia: (state, pos) => {
    const { frecuencias } = state
    frecuencias.push({
      frecuencia: frecuencias[pos].frecuencia,
      fromValidez: frecuencias[pos].fromValidez,
      toValidez: frecuencias[pos].toValidez,
      check_in: frecuencias[pos].check_in,
      hour_departure: frecuencias[pos].hour_departure,
      terminal_departure: frecuencias[pos].terminal_departure,
      puerta_departure: frecuencias[pos].puerta_departure,
      hour_arrival: frecuencias[pos].hour_arrival,
      terminal_arrival: frecuencias[pos].terminal_arrival,
      puerta_arrival: frecuencias[pos].puerta_arrival,
      time_vuelo_h: frecuencias[pos].time_vuelo_h,
      time_vuelo_m: frecuencias[pos].time_vuelo_m,
    })
  },
  deleteFrecuencia: (state, pos) => {
    // state.loadFrecuencia = true
    const { frecuencias } = state

    frecuencias.splice(pos, 1)

    /* setTimeout(() => {
      state.loadFrecuencia = false
    }, 100) */
  },

  // CLASES DE VUELOS
  initClaseVuelo: state => {
    const { clasesVuelos } = state

    clasesVuelos.push({
      class_id: 0,
      tarifa_id: 0,
      cupo: 0,
      price_adult: 0,
      price_child: 0,
      price_infant: 0,

      equipaje_cabina: {
        dimensiones: 0,
        peso: 0,
        precio: 0,
        aplica_adult: false,
        aplica_child: false,
        aplica_inf: false,
      },

      equipaje_bodega: {
        dimensiones: 0,
        peso: 0,
        maletas: [0],
        max_exceso_equipaje: 0,
        exceso_equipaje: 0,
        tasa_despacho: 0,
        aplica_adult: false,
        aplica_child: false,
        aplica_inf: false,
      },
    })
    state.clasesVuelos = clasesVuelos
  },
  adicionarClaseVuelo: state => {
    const { clasesVuelos } = state

    clasesVuelos.push({
      class_id: 0,
      tarifa_id: 0,
      cupo: 0,
      price_adult: 0,
      price_child: 0,
      price_infant: 0,

      equipaje_cabina: {
        dimensiones: 0,
        peso: 0,
        precio: 0,
        aplica_adult: false,
        aplica_child: false,
        aplica_inf: false,
      },

      equipaje_bodega: {
        dimensiones: 0,
        peso: 0,
        um_peso: '',
        maletas: [0],
        max_exceso_equipaje: 0,
        exceso_equipaje: 0,
        tasa_despacho: 0,
        aplica_adult: false,
        aplica_child: false,
        aplica_inf: false,
      },
    })
    state.clasesVuelos = clasesVuelos
  },
  adicionarMaletaCabina: state => {
    const { clasesVuelos } = state

    clasesVuelos.forEach(element => {
      element.equipaje_bodega.maletas.push(0)
    })
  },
  deleteMaletaBodegaVuelo: (state, pos) => {
    const { clasesVuelos } = state

    clasesVuelos.forEach(element => {
      element.equipaje_bodega.maletas.splice(pos, 1)
    })
  },
  adicionarPrecioAdicionalVuelo: (state, clase) => {
    const { clasesVuelos } = state

    clasesVuelos.forEach(element => {
      if (element.class_id === clase.class_id) {
        element.precio_adicional.froms.push({
          from: 0,
          to: 0,
          price: 0,
          aplica_adult: false,
          aplica_child: false,
          aplica_inf: false,
        })
      }
    })
  },
  eliminarPrecioAdicionalVuelo: (state, { clase, from }) => {
    const { clasesVuelos } = state

    const classEdit = clasesVuelos.filter(geeks => geeks.class_id === clase.class_id)

    if (classEdit.length > 0) {
      const { froms } = classEdit[0].precio_adicional

      const fromsR = froms.filter(geeks => geeks.from !== from.from)
      // eslint-disable-next-line no-param-reassign
      clase.precio_adicional.froms = fromsR
    }
  },

  updateClaseVuelo: (state, clases) => {
    state.clasesVuelos = clases
  },
  deleteClaseVuelo: (state, pos) => {
    state.loadClasesVuelos = true
    const { clasesVuelos } = state

    clasesVuelos.splice(pos, 1)

    // state.clasesVuelos = clasesVuelos.filter(geeks => geeks.class_id !== clase.class_id)
    setTimeout(() => {
      state.loadClasesVuelos = false
    }, 100)
  },

  // Temporadas Markus
  adicionarTemporadaFlightMarkus: state => {
    state.temporadasFlightMarkups.push({
      fromValidez: '',
      toValidez: '',
    })
  },
  updateTemporadaFlightMarkus: (state, temporadasFlightMarkups) => {
    state.temporadasFlightMarkups = temporadasFlightMarkups
  },
  initMarkupsFlight: (state, typesAfiliados) => {
    state.markupsFlightMarkups.push({
      id_tipo_afiliado: 0,
      name_tipo_afiliado: 'Ventas Directas',
      valorAdult: '',
      pcAdult: '',
      valorChild: '',
      pcChild: '',
      valorInf: '',
      pcInf: '',
    })

    typesAfiliados.forEach(element => {
      state.markupsFlightMarkups.push({
        id_tipo_afiliado: element.id,
        name_tipo_afiliado: element.name,
        valorAdult: '',
        pcAdult: '',
        valorChild: '',
        pcChild: '',
        valorInf: '',
        pcInf: '',
      })
    })
  },
  updateMarkusFlight: (state, markupsFlightMarkups) => {
    state.markupsFlightMarkups = markupsFlightMarkups
  },
  deleteTemporadaFlightMarkus: (state, pos) => {
    const { temporadasFlightMarkups } = state

    temporadasFlightMarkups.splice(pos, 1)
  },

  // CARS
  updateGeoTag: (state, geotag) => {
    state.geotag = geotag
  },
  adicionarTelefono: state => {
    const { telefonos } = state
    telefonos.push({ number: '' })
  },
  deleteTelefono: (state, pos) => {
    const { telefonos } = state

    telefonos.splice(pos, 1)
  },
  updateTelefonos: (state, telefonos) => {
    state.telefonos = telefonos
  },
  adicionarHorario: state => {
    state.horarios.push({
      modelSemana: [0, 1, 2, 3, 4, 5, 6],
      is_time_completo: true,
      horarios: [],
    })
  },
  deleteHorario: (state, pos) => {
    const { horarios } = state

    horarios.splice(pos, 1)
  },
  adicionarHorarioComponent: (state, pos) => {
    state.horarios[pos].horarios.push({ from: '', to: '' })
  },
  deleteHorarioComponent: (state, { posH, pos }) => {
    const { horarios } = state
    horarios[posH].horarios.splice(pos, 1)
  },
  updateHorarios: (state, horarios) => {
    state.horarios = horarios
  },
  setTimeCompletoOficinal: (state, { pos, isTimeCompleto }) => {
    state.horarios[pos].is_time_completo = isTimeCompleto
    if (!isTimeCompleto) {
      state.horarios[pos].horarios = [{ from: '', to: '' }]
    } else {
      state.horarios[pos].horarios = []
    }
  },
  adicionarImgCars: (state, galery) => {
    galery.forEach(element => {
      state.galeria.push({ tag: null, image: element })
    })
  },
  deleteImgCars: (state, pos) => {
    const { galeria } = state

    galeria.splice(pos, 1)
  },
  updateImgCars: (state, galery) => {
    state.galeria = galery
  },
}
