// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // OFFICES RENT
  {
    path: '/offices-rent-cars/list',
    name: 'offices-rent-cars-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/cars/OfficesRent.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_oficina_renta_cars',
    },
  },

  // PTO DE RECOGIDA
  {
    path: '/point-pickup-cars/list',
    name: 'pto-recogida-cars-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/cars/PtoRecogida.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_tags_pto_recogida_cars',
    },
  },

  // TRANSMITION
  {
    path: '/transmition-cars/list',
    name: 'transmition-cars-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/cars/Transmition.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_transmision_cars',
    },
  },

  // RENTADORAS
  {
    path: '/rents-cars/list',
    name: 'rents-cars-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/nomencladores/cars/Rents.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'nom_rentadora_cars',
    },
  },
]

export default formTable
