import mutations from './mutations'
import actions from './actions'

const state = {
  shallContentShowOverlay: false,
  app_name: process.env.VUE_APP_NAME,
  user: {
    permisos: [],
  },
  viewPermisos: null,
  isLoadingPagePermisos: true,
  sourcePermisos: [],
  sourceTableros: [],
  updateBooking: false,
  showBooking: true,
  showProduct: true,
  showContrate: true,
  showAdministration: true,
  showConfiguration: true,
  showNomencladores: true,
  showNomencladoresFlights: true,
  showNomencladoresCars: false,

  userProfile: {},
  isLoadingPage: true,
  perfilesAll: [],
  perfilesAuth: [],
  permisosItem: [],
  cargandoMenu: true,

  itemsPerPage: 10,

  loadEscalas: false,
  escalas: [],

  loadFrecuencias: false,
  frecuencias: [],

  loadClasesVuelos: false,
  loadTarifasVuelos: false,
  clasesVuelos: [],
  umPesoBodega: '',

  // USERS
  searchUsers: {
    name: null,
    company: 0,
    rol: 0,
    active: 2,
  },
  userUpdate: {
    id: null,
    name: null,
    apellidos: null,
    email: null,
    role_id: null,
    active: 1,
  },
  userOnlyShow: false,
  userUpdateAvatar: null,
  userItemsPerPage: 10,
  totalUsersFilters: 0,
  totalUsersAll: 0,

  // MARKUPS
  temporadasFlightMarkups: [],
  markupsFlightMarkups: [],

  // GEOTAG
  geotag: {
    longitud: null,
    latitud: null,
  },

  // FLIGHTS
  airport: {
    iata: null,
    name: null,
    country: null,
    state: null,
    city: null,
    direccion: null,
  },
  oficinaRenta: {
    name: null,
    ptos_recogida: null,
    country: null,
    state: null,
    city: null,
    direccion: null,
  },
  onlyShow: false,

  // CARS
  telefonos: [],
  horarios: [],
  galeria: [],

  // COTIZADOR
  cotizadorFlight: {
    cant_adults: 1,
    cant_ninnos: 0,
    cant_infant: 0,
    aerolinea: 0,
    onlyIda: false,
    destinosMultiples: false,
  },
  destinosCotizadorFlight: [
    {
      origen: null,
      destino: null,
      from: null,
      to: null,
    },
  ],
  searchingFlightCotizador: false,
  resultCotizadorIdaFlight: [],
  aerolineCotizadorIdaFlight: [],
  aerolineCotizadorRegresoFlight: [],
  aerolineCotizadorDestinosFlight: [],
  tarifaEconCotizadorIdaFlight: {},
  resultCotizadorBackFlight: [],
  filtrosCotizadorFlight: {
    aerolinea: 0,
    escala: 0,
    duracionEscala: 20,
    duracionViaje: 20,
    frecuencias: ['0', '1', '2', '3', '4', '5', '6'],
    airport: {},
    clase: 0,
    equipaje: 0,
    byMejorOpcion: true,
    byMasEconomico: false,
    byMasRapido: false,
  },
  scheduleResult: [],
  pageResult: 1,
  itemsPerPageResult: 10,
}

export default {
  state,
  mutations,
  actions,
}
